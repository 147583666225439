<template>
  <div class="fill-height">
    <v-app-bar color="primary" app class="white--text d-print-none">
      <v-app-bar-nav-icon
        class="white--text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>{{
        $vuetify.lang.t(`$vuetify.appTitle`)
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <form @submit.prevent="search">
        <v-text-field
          class="search"
          dense
          outlined
          hide-details
          filled
          dark
          type="search"
          v-model="searchText"
        >
          <template #append>
            <v-btn small icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </form>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary right app touchless>
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ `${user.firstName} ${user.lastName}` }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                user.mobileNo | persianNumber()
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{
              $vuetify.lang.t(`$vuetify.logout`)
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider v-if="role !== 'client'"></v-divider>
      <v-dialog
        v-if="role !== 'client'"
        persistent
        width="500"
        v-model="uploadModal"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-file-excel-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="$vuetify.lang.t(`$vuetify.uploadXLSX`)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-card tag="form" @submit.prevent="uploadFile">
          <v-card-title>
            {{ $vuetify.lang.t(`$vuetify.uploadXLSX`) }}
          </v-card-title>
          <v-card-text>
            <v-file-input
              show-size
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :label="$vuetify.lang.t(`$vuetify.selectFile`)"
              v-model="file"
            ></v-file-input>
            <v-progress-linear
              v-if="uploadProgress"
              v-model="uploadProgress"
              color="success"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn type="button" text @click="uploadModal = false">
              {{ $vuetify.lang.t(`$vuetify.cancel`) }}
            </v-btn>
            <v-btn type="submit" :disabled="!file" color="primary" text>
              {{ $vuetify.lang.t(`$vuetify.upload`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="item in children"
            :key="item.name"
            :to="{ name: item.name }"
            :disabled="item.meta.disabledInNav"
          >
            <v-list-item-icon>
              <v-icon v-text="item.meta.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-text="$vuetify.lang.t(`$vuetify.routes.${item.name}`)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main class="fill-height">
      <router-view :key="$route.path"></router-view>
    </v-main>
  </div>
</template>

<script>
import { calendarService } from "@/services/calendar";
export default {
  data: () => ({
    drawer: false,
    selectedItem: 0,
    searchText: "",
    file: null,
    uploadModal: false,
    uploadProgress: 0
  }),
  computed: {
    children() {
      return this.$router.options.routes
        .find(route => route.name === "dashboard")
        .children.filter(
          route =>
            route.meta.roles.includes(this.role) &&
            route.name &&
            !route.redirect
        );
    },
    user() {
      return this.$store.getters["auth/getUser"];
    },
    role() {
      return this.$store.getters["auth/getUserRole"];
    }
  },
  watch: {
    selectedItem() {
      this.drawer = false;
    }
  },
  methods: {
    uploadFile() {
      var file = new FormData();
      file.append("events", this.file);
      calendarService
        .uploadXLSX(file, value => (this.uploadProgress = value))
        .then(() => {
          this.uploadModal = false;
        })
        .finally(() => {
          this.uploadProgress = 0;
        });
    },
    search() {
      if (this.searchText) {
        this.$router.push({
          name: "calendar",
          query: { title: this.searchText }
        });
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  width: 200px;
  max-width: calc(100vw - 100px);
  transition: width 0.3s;
  &.v-input--is-focused {
    width: 400px;
  }
}
</style>
